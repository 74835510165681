.SwitchMyFleetLogo {
    position: absolute;
    z-index: 1002;
    left: 46%;
}

@media (max-width: 1440px) {
    .SwitchMyFleetLogo {
        left: 42%;
    }
}

@media (max-width: 1200px) {
    .SwitchMyFleetLogo {
        left: 38%;
    }
}

@media (max-width: 992px) {
    .SwitchMyFleetLogo {
        left: 34%;
    }
}

@media (max-width: 768px) {
    .SwitchMyFleetLogo {
        left: 30%;
    }
}

@media (max-width: 575px) {
    .SwitchMyFleetLogo {
        left: 26%;
    }
}

@media (max-width: 480px) {
    .SwitchMyFleetLogo {
        left: 22%;
    }
}

@media (max-width: 375px) {
    .SwitchMyFleetLogo {
        left: 18%;
    }
}

.iconFont {
    font-size: 1.5rem;
    vertical-align: sub;
    color: #777777;

}

.navbarCustom {
    height: 50px;
    background-color: #f8f8f8;
    align-content: center;
}

.navCollapse {
    background-color: #f8f8f8;
    z-index: 1001;
}

.navCustom {
    align-items: center;
}

.imageSize26 {
    width: 26px;
    height: 26px;
}

.imageSize32 {
    width: 32px;
    height: 32px;
}
