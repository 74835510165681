.outterDiv {
  position: relative;
}

.textEnd {
  text-align: end;
}

.textCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputGroupCustom {
  width: 100%;
}

#inputGroupAddonCustom {
  width: 50%;
  white-space: normal;
  line-height: 1;
  font-size: 14px;
  justify-content: center;
}
