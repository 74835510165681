@media all and (min-width: 480px) {
  .confirmationForm {
    padding: 60px 0;
  }
  .confirmationForm form {
    margin: 0 auto;
    max-width: 320px;
  }
  .success {
    max-width: 400px;
  }
}

@media all and (max-width: 480px) {
  .confirmationForm form {
    margin: 20px auto;
    max-width: 320px;
  }
}

.confirmationForm form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.success {
  margin: 0 auto;
  text-align: center;
}

.iconOk {
  color: grey;
  font-size: 30px;
  margin-bottom: 30px;
}