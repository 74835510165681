.layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background: black center center no-repeat;
    opacity: 0.4;
    transition: 0.5s;
    z-index: 1001;
}

.spinnerBox {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.button {
    display: block;
    margin: 0 auto;
}
