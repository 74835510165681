.cornerRibbon{
  z-index: 9999;
  width: 400px;
  background: red;
  color: #fff;
  position: fixed;
  text-align: center;
  bottom: 100px;
  line-height: 40px;
  right: -100px;
  left: auto;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}