.container {
  background-color: #3C5E47;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  z-index: 9999;
}

.outDiv {
  display: flex;
  height: 100%;
}

.innerDiv {
  margin: auto;
}

#nationalMapLogo {
  width: 160px;
}

#iqOfficeLogo {
  width: 120px;
}

#eecaLogo {
  width: 160px;
}

.images {
  margin-right: 20px;
}