.iconLeft {
    position: absolute;
    left: 3%;
    top: 20%;
    font-size: 1.5rem;
}

.iconRight {
    position: absolute;
    right: 3%;
    top: 20%;
    font-size: 1.5rem;
}

.panelHeading button {
    background-color: #ffc322;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    text-align: center;
}

.panelHeading button:not(.collapsed) {
    color: white;
    border-radius: 4px;
    background-color: #ffc322;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
