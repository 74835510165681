.greyColor {
    color: #777777 !important;
}

.yellowColor {
    color: #ffc322 !important;
}

p.redColor {
    color: red;
}

p.marginLeft {
    margin-left: 5px;
}

.textCenter {
    text-align: center;
}

.cellFont {
    font-size: medium;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
}

.tabs {
    margin-top: 10px;
}

.tabHead {
    color: #777;
    font-size: 14px;
}

.tabHeadSvg {
    vertical-align: middle;
}

.outputTable tr td {
    vertical-align: middle;
    text-align: center;
}
.pageBreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
}

.routeButtonSize {
    font-size: 12px !important;
}
@media print {
    .componentTitle {
        font-size: large;
        font-weight: 650;
        line-height: 1;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: start;
    }

    .textAlignLeft {
        text-align: start;
    }
    .outputTable tr td {
        --bs-table-accent-bg: #fff !important;
    }

    .cellFont {
        font-size: small;
        font-weight: 400;
        color: #555;
        line-height: 0.8;
        margin-top: 5px;
        margin-bottom: 5px;
        vertical-align: middle;
    }
}
