$nav-tabs-border-color: black;
$nav-tabs-border-width: 2px;
$nav-tabs-link-active-border-color: black black white black;
$btn-font-size: 14px;
$input-font-size: 14px;
$input-font-color: #555;
$navbar-toggler-font-size: 1rem;
$navbar-light-color: rgba(#ffc322, 0.55);
$navbar-padding-x: 10px;
$navbar-nav-link-padding-x: 1rem;
$form-label-font-weight: 700;
$accordion-padding-y: 14px;
$accordion-border-width: 3px;
$accordion-border-color: #fff;
$accordion-transition: border-radius 4px 0.15s ease;
$accordion-inner-border-radius: 4px;
$accordion-border-radius: 4px;
$tooltip-font-size: 0.9em;
$dropdown-font-size: 14px;
$dropdown-border-color: #ccc;
$btn-font-size-lg: 18px;
$input-font-size-lg: 18px;
$primary: #337ab7;
$warming: #ffc322;
$secondary: #ced4da;
$body-color: #555;
$link-color: #25577e;
@import "~bootstrap/scss/bootstrap";
