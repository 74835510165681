/* Common */

body {
    margin: 0;
    overflow-y: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 14px;
}

svg:not(:root) {
    overflow: visible;
}

.btn-outline-secondary {
    background-color: #fff;
    color: #555;
}

.hidden {
    display: none !important;
}

.input-group-text {
    font-size: 14px;
}

@media print {
    @page {
        size: portrait;
        margin-top: 16mm;
        margin-bottom: 16mm;
        margin-left: 25mm;
        margin-right: 25mm;
    }

    .zoom10 {
        zoom: 10%;
    }

    .zoom15 {
        zoom: 15%;
    }

    .zoom20 {
        zoom: 20%;
    }

    .zoom25 {
        zoom: 25%;
    }

    .zoom30 {
        zoom: 30%;
    }

    .zoom35 {
        zoom: 35%;
    }

    .zoom40 {
        zoom: 40%;
    }

    .zoom45 {
        zoom: 45%;
    }

    .zoom50 {
        zoom: 50%;
    }

    .zoom55 {
        zoom: 55%;
    }

    .zoom60 {
        zoom: 60%;
    }

    .zoom65 {
        zoom: 65%;
    }

    .zoom70 {
        zoom: 70%;
    }

    .zoom75 {
        zoom: 75%;
    }

    .zoom80 {
        zoom: 80%;
    }

    .zoom85 {
        zoom: 85%;
    }

    .zoom90 {
        zoom: 90%;
    }

    .zoom95 {
        zoom: 95%;
    }

    .printhide {
        display: none;
    }

    .nav-tabs {
        display: none;
    }

    .printshow {
        display: inline !important;
    }

    .printshowBlock {
        display: block !important;
    }

    .printshowTable {
        display: table !important;
    }

    .page-break {
        display: block;
        page-break-before: always;
    }

    .page-break-after {
        display: block;
        page-break-after: always;
    }

    .print-title {
        color: #00b7ed !important;
        font-size: 20px;
        font-weight: 700;
        padding-right: 40px;
    }

    #accordion-location-direction {
        width: 100%;
    }

    .leaflet-control-container {
        display: none;
    }

    #printTable {
        margin-top: 20px;
    }
}

label {
    color: #444444;
}

/* Header */

.navbar-brand {
    display: flex;
    align-items: center;
}


.navbar-nav > a:hover {
    background-color: #00000021 !important;
}

/* DeliveryAddressGrid */

.react-grid-Header .widget-HeaderCell__value {
    color: #444444;
}

.react-grid-Grid {
    min-height: 300px !important;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #8b8a8f;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #8b8a8f;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #8b8a8f;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #8b8a8f;
}

.tooltip-inner {
    background-color: #8b8a8f;
}

#sampleAddress .dropdown-menu {
    width: 100%;
}

#unmatchedAddress .dropdown-menu {
    width: 100%;
}
.dropdown-menu {
    width: 50%;
}

.input-group .form-control {
    text-align: end;
    min-height: 42px;
}

/* OutputPanel */

.tab-content {
    margin-top: 10px;
}

/* SmartRouting */

.leaflet-container {
    height: 100%;
    text-align: right;
    margin: 0 auto;
    background-color: #d1e1e5;
}

.leaflet-tooltip {
    background-color: transparent;
    border: 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
    color: transparent;
}

.leaflet-tooltip:before {
    padding: 0px;
    color: transparent;
    border: 0px;
    box-shadow: transparent;
}

.leaflet-tooltip-top {
    margin: 0px;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

@media (max-width: 821px) {
    #accordion-location-direction {
        padding-right: 3px;
    }
}

#react-joyride-step-0 button[title="Close"] {
    display: none;
}

#react-joyride-step-14 button[title="Close"] {
    display: none;
}

button[data-action="skip"] {
    cursor: not-allowed;
    pointer-events: none;
}

#react-joyride-step-1 button[title="Next →"]:before {
    content: "1/5 ";
}

#react-joyride-step-2 button[title="Next →"]:before {
    content: "2/5 ";
}

#react-joyride-step-3 button[title="Next →"]:before {
    content: "3/5 ";
}

#react-joyride-step-4 button[title="Next →"]:before {
    content: "4/5 ";
}

#react-joyride-step-5 button[title="Next →"]:before {
    content: "5/5 ";
}

#react-joyride-step-6 button[title="Next →"]:before {
    content: "1/2 ";
}

#react-joyride-step-7 button[title="Next →"]:before {
    content: "2/2 ";
}

#react-joyride-step-8 button[title="Next →"]:before {
    content: "1/6 ";
}

#react-joyride-step-9 button[title="Next →"]:before {
    content: "2/6 ";
}

#react-joyride-step-10 button[title="Next →"]:before {
    content: "3/6 ";
}

#react-joyride-step-11 button[title="Next →"]:before {
    content: "4/6 ";
}

#react-joyride-step-12 button[title="Next →"]:before {
    content: "5/6 ";
}

#react-joyride-step-13 button[title="Next →"]:before {
    content: "6/6 ";
}
