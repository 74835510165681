.printLogo {
    height: 60px;
}

.critchlowLogo {
    margin: 5px auto;
}

.textAlignLeft {
    text-align: start;
    float: left;
}

.textAlignRight {
    text-align: end;
    float: right;
}