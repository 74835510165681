@media all and (max-width: 480px) {
    .login form {
        margin: 20px auto;
        max-width: 420px;
    }
}

@media all and (min-width: 480px) {
    .login form {
        margin: 0 auto;
        max-width: 420px;
    }
}

@media all and (min-width: 720px) {
    .login ul li {
        display: flex;
        float: left;
        width: 60%;
    }
}

.images {
    margin-top: 10px;
    display: block;
}

#blankSpace {
    height: 30px;
}

label.title {
    color: #00b7ed;
}

.ulLogo {
    max-width: 300px;
    margin: 0 auto;
}

.liLeft {
    list-style-type: none;
    text-align: left;
}

.liCenter {
    list-style-type: none;
    text-align: center;
    margin: 0 auto;
}

.divCenter {
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.liRight {
    list-style-type: none;
    text-align: right;
}

#newZealandMadeDiv {
    position: absolute;
    right: 10px;
    bottom: 20px;
}

#newZealandMade {
    width: 150px;
}

#iqOffice {
    width: 200px;
    margin-left: -10px;
}

#nationalMapLogoBlackBlue {
    width: 200px;
    margin-bottom: 20px;
}

#glAGenLessOrganisationBLK {
    width: 250px;
    margin-left: -5px;
}

#eeca {
    width: 300px;
    margin-left: -15px;
    margin-bottom: 10px;
}

.login {
    /* height: 94.5vh; */
    position: absolute;
    width: 100%;
    height: 85%;
}

.loginLeft {
    height: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
}

.loginRight {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerBox {
    width: 100%;
    height: 50%;
    overflow-wrap: break-word;
}

.innerDiv {
    padding: 60px 0;
    width: 100%;
    height: 50%;
    margin: 0px auto;
    /* background-color: red; */
}

.form {
    width: 100%;
    text-align: center;
}

.formControl {
    max-width: 320px;
    margin: 0px auto;
}

#learnMoreTitle {
    color: red;
    align-items: center;
}

#learnMoreBtn {
    padding: 5px 20px;
    border: 2px solid #2e6143;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    background-color: #2e6143;
}

#learnMoreBtn:hover,
#learnMoreBtn:active,
#learnMoreBtn.active,
.open .dropdown-toggle#learnMoreBtn {
    color: #2e6143;
    background-color: #fff;
    border: 2px solid #2e6143;
}

#learnMoreBtn:focus {
    outline: none !important;
    box-shadow: none !important;
}
.alignCenter {
    align-items: center;
}
.alignJustify {
    text-align: justify;
}
#learnMorePanel {
    border: 3px solid #2e6143;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 20px;
}
