.root {
    margin: 10px;
}

#sampleCity {
    width: 100%;
    height: 42px;
}

.textCenter {
    text-align: center;
    width: 100%;
}

.subLabel {
    color: #9e9e9e;
    padding-bottom: 10px;
}

.bpAddAddress {
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
}

#spanDelivery {
    margin: auto 0;
    width: 50%;
}

#locationEntryFormSubmitAddress {
    display: flex;
    justify-content: center;
}

.inlineFlex {
    /* width: 100%; */
    flex-wrap: wrap;
    display: flex;
}

.rightSide {
    margin-left: auto;
}

.formGroupTitle {
    color: #00b6ed;
}

.errorMsg {
    color: red;
}
