.success {
  margin: 0 auto;
  text-align: center;
}

.iconOk {
  color: grey;
  font-size: 30px;
  margin-bottom: 30px;
}

@media all and (min-width: 480px) {
  .resetPassword {
    padding: 60px 0;
  }

  .resetPassword form {
    margin: 0 auto;
    max-width: 320px;
  }

  .success {
    max-width: 320px;
  }
}

@media all and (max-width: 480px) {
  .resetPassword form {
    margin: 20px auto;
    max-width: 320px;
  }
}
