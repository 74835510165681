.textAlignLeft {
  text-align: start;
}

.textCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tabs {
  margin-top: 10px;
}

.inputGroupCustom {
  width: 100%;
}

.inputGroupAddonCustom {
  width: 50%;
  white-space: normal;
}

.tabHead {
  color: #777;
}

.tabHeadSvg {
  vertical-align: middle;
}

@media print {
  .reportTitle {
    color: #00B7ED !important;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .fontItalic {
    font-style: italic;
  }

  .componentTitle {
    font-size: large;
    font-weight: 650;
    line-height: 1.0;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    text-align: start;
  }

  .cellSubTitle {
    font-size: medium;
    font-weight: 500;
    line-height: 0.8;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    text-align: start;
  }

  .cellFont {
    font-size: small;
    font-weight: 400;
    line-height: 0.8;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
  }

  .borderTopNone {
    border-top: none !important;
  }
}