.mapWrapper {
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
}

.textCenter {
    text-align: center;
}

.panelBody {
    overflow-y: auto;
    overflow-x: hidden;
}

#accordionLocationDirection a:focus, a:hover {
    text-decoration: none;
}