.container {
    color: #000000;
    border: solid 1px #cce0ff;
    padding: 6px 8px;
    background: #C4C4C4;
    opacity: 0.7;
    border-radius: 5px;
}

.leftFont {
    margin-right: 35px;
    font-size: 15px;
    font-weight: 550;
    vertical-align: middle;
}

.rightImage {
    margin-right: 15px;
}