.coachMarkTitle {
  color: #ffc322;
}

.fontItalic {
  font-style: italic;
}

.pickFont {
  color: #f04;
}