#genRunTypeId,
#evTypeId,
#iceTypeId {
  width: 50%;
  min-height: 42px;
}

.textCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs {
  margin-top: 10px;
}

.inputGroupCustom {
  width: 100%;
}

.inputGroupAddonCustom {
  width: 50%;
  white-space: normal;
  font-size: 14px;
  line-height: 1;
  justify-content: center;
}

.tabHead {
  color: #777;
  font-size: 14px;
}

.tabHeadSvg {
  vertical-align: middle;
}

.unit {
  width: 45px;
  justify-content: center;
}
